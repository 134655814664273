import request from '@/utils/request'


// 查询广告-banner列表
export function listBanner(query) {
  return request({
    url: '/banner/banner/list',
    method: 'get',
    params: query
  })
}

// 查询广告-banner分页
export function pageBanner(query) {
  return request({
    url: '/banner/banner/page',
    method: 'get',
    params: query
  })
}

// 查询广告-banner详细
export function getBanner(data) {
  return request({
    url: '/banner/banner/detail',
    method: 'get',
    params: data
  })
}

// 新增广告-banner
export function addBanner(data) {
  return request({
    url: '/banner/banner/add',
    method: 'post',
    data: data
  })
}

// 修改广告-banner
export function updateBanner(data) {
  return request({
    url: '/banner/banner/edit',
    method: 'post',
    data: data
  })
}

// 删除广告-banner
export function delBanner(data) {
  return request({
    url: '/banner/banner/delete',
    method: 'post',
    data: data
  })
}
